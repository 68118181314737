import React from "react";
import { graphql, PageProps } from "gatsby";
import pagesMeta from "data/pagesMeta";
import SEO from "components/SEO";

import {
  Hero,
  Stories,
  SpotlightList,
  Testimonial,
  CaseStudy,
  BusinessSupport,
} from "../../components/customers";

const Customers: React.FC<PageProps> = ({ data }) => {
  const {
    spotlights: { edges: spotlights },
    caseStudies: { edges: caseStudies },
  } = data as any;

  return (
    <>
      <SEO {...pagesMeta.customers} />
      <Hero />
      <Stories />
      <SpotlightList spotlights={spotlights[0].node.data as any} />
      <BusinessSupport />
      {caseStudies[0].node.data.length !== 0 && (
        <CaseStudy caseStudies={caseStudies[0].node.data as any} />
      )}
      <Testimonial />
    </>
  );
};

export const pageQuery = graphql`
  query CustomersIndexQuery {
    spotlights: allStrapiSpotlight {
      edges {
        node {
          data {
            id
            attributes {
              description
              title
              url
              poster {
                data {
                  attributes {
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    url
                  }
                }
              }
            }
          }
        }
      }
    }

    caseStudies: allStrapiCaseStudy {
      edges {
        node {
          data {
            id
            attributes {
              cover_img {
                data {
                  attributes {
                    ext
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
              company_name
              title
              logo {
                data {
                  attributes {
                    ext
                    url
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Customers;
